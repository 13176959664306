import Swiper from 'swiper/bundle';
export default class Carousel {
  constructor(element) {
    this.element = element;
    this.options = {
      slidesPerView: 1,
      effect: '',
      spaceBetween: 0,
      pagination: {
        el: this.element.querySelector('.swiper-pagination'),
        type: 'bullets',
      },
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
      breakpoints: '',
      autoplay: '',
      loop: '',
    };
    this.init();
  }

  init() {
    this.setOptions();
    new Swiper(this.element, this.options);
  }

  setOptions() {
    const variant = this.element.dataset.variant;
    if (variant == 'split') {
      this.options.breakpoints = {
        768: {
          slidesPerView: 2.5,
        },
      };
    }
    if (variant == 'split2') {
      this.options.breakpoints = {
        768: {
          slidesPerView: 2,
        },
      };
    }
    if (variant == 'fraction') {
      (this.options.pagination = {
        el: '.swiper-pagination',
        type: 'fraction',
      }),
        (this.options.breakpoints = {
          1064: {
            slidesPerView: 2.5,
          },
        }),
        (this.options.grabCursor = true);
      this.options.slidesPerView = 1.2;
      this.options.spaceBetween = 20;
    }
    if ('autoplay' in this.element.dataset) {
      this.options.autoplay = {
        delay: 2000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      };
    }
    if ('loop' in this.element.dataset) {
      this.options.loop = true;
    }
    if ('effect' in this.element.dataset) {
      this.options.effect = this.element.dataset.effect;
    }
    if ('gap' in this.element.dataset) {
      const gap = parseInt(this.element.dataset.gap);
      this.options.spaceBetween = gap;
    }
  }
}
