export default class Header {
  constructor(element) {
    this.element = element;
    this.scrollLimit = this.element.getAttribute('data-scroll-limit');
    this.hiding = this.element.getAttribute('data-not-hiding');
    this.scrollPosition = 0;
    this.lastScrollPosition = 0;
    this.html = document.documentElement;
    this.init();
    this.initNavMobile();
  }

  init() {
    window.addEventListener('scroll', this.onScroll.bind(this));
  }

  onScroll(event) {
    this.lastScrollPosition = this.scrollPosition;
    this.scrollPosition = document.scrollingElement.scrollTop;

    this.setHeaderState();
    this.setDirectionState();
  }

  setHeaderState() {
    const scrollHeight = document.scrollingElement.scrollHeight;
    if (
      this.scrollPosition > scrollHeight * this.scrollLimit &&
      this.hiding == 'false'
    ) {
      this.html.classList.add('header-is-hidden');
    } else {
      this.html.classList.remove('header-is-hidden');
    }
  }

  setDirectionState() {
    if (this.scrollPosition >= this.lastScrollPosition) {
      this.html.classList.add('is-scrolling-down');
      this.html.classList.remove('is-scrolling-up');
    } else {
      this.html.classList.remove('is-scrolling-down');
      this.html.classList.add('is-scrolling-up');
    }
  }
  initNavMobile() {
    const toggle = this.element.querySelector('.js-toggle');
    const toggle2 = this.element.querySelectorAll('.nav-toggle');
    toggle.addEventListener('click', this.onToggleNav.bind(this));
    for (let i = 0; i < toggle2.length; i++) {
      const toggles = toggle2[i];
      toggles.addEventListener('click', this.onToggleNav2.bind(this));
    }
  }
  onToggleNav() {
    this.html.classList.toggle('nav-is-active');
  }

  onToggleNav2() {
    this.html.classList.add('nav-is-active');
    this.html.classList.remove('nav-is-active');
  }
}
