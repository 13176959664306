export default class Scrolly {
  constructor(element) {
    this.element = element;

    this.init();
    this.options = {
      rootMargin: '0px',
    };
  }

  init() {
    const observer = new IntersectionObserver(
      this.watch.bind(this),
      this.options
    );
    const items = this.element.querySelectorAll('[data-scrolly]');
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      observer.observe(item);
    }
  }
  watch(entries, observer) {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      const target = entry.target;
      let noRepeat = this.element.getAttribute('data-no-repeat');
      if (entry.isIntersecting) {
        target.classList.add('is-active');
        if (noRepeat == 'true') {
          observer.unobserve(target);
        }
      } else {
        target.classList.remove('is-active');
      }
    }
  }
}
