import VanillaTilt from 'vanilla-tilt';

export default class Tilt {
  constructor(element) {
    this.element = element;
    this.options = {
      max: 10,
      speed: 90,
      perspective: 500,
    };
    this.init();
  }
  init() {
    this.setOptions();
    new VanillaTilt(this.element, this.options);
  }
  setOptions() {
    const variant = this.element.dataset.variantTilt;
    if (variant == 'projets') {
      this.options = {
        max: 5,
      };
    }
    if (variant == 'social') {
      this.options = {
        max: 30,
      };
    }
    if (variant == 'stage') {
      this.options = {
        max: 30,
        scale: 1.2,
      };
    }
  }
}
