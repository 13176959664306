export default class Terminal {
  constructor(element) {
    this.element = element;
    this.targetText = this.element.dataset.text; // récupère le texte à afficher
    this.textElement = this.element.querySelector('.terminal__text'); // récupère l'élément où afficher le texte
    this.isDeleting = false; // pour savoir si on est en train de supprimer le texte
    this.currentIndex = 0; // index du caractère à afficher
    this.init();

    this.delaytoend = 5000; // durée avant suppression du texte
  }

  init() {
    this.typeText();
  }

  typeText() {
    if (!this.isDeleting) {
      // si on n'est pas en train de supprimer le texte
      if (this.currentIndex < this.targetText.length) {
        // si on n'a pas atteint la fin du texte
        this.textElement.textContent += this.targetText[this.currentIndex]; // on ajoute le caractère suivant
        this.currentIndex++; // on incrémente l'index du caractère à afficher
        setTimeout(this.typeText.bind(this), 50); // on rappelle la fonction dans 100ms et la vitesse d'écriture
      } else {
        setTimeout(
          // on attend 900ms avant de supprimer le texte
          function () {
            // on passe isDeleting à true et on rappelle la fonction
            this.isDeleting = true; // pour indiquer que on supprime le texte
            this.typeText(); // on rappelle la fonction
          }.bind(this), // on bind le this pour pouvoir utiliser this dans la fonction
          this.delaytoend // on attend delaytoend avant de supprimer le texte
        );
      }
    } else {
      // si on est en train de supprimer le texte
      if (this.currentIndex > 0) {
        // si on n'a pas supprimé tout le texte
        this.textElement.textContent = this.textElement.textContent.slice(
          // on supprime le dernier caractère
          0, // à partir de l'index 0
          -1 // jusqu'à l'avant dernier caractère
        );
        this.currentIndex--; // on décrémente l'index du caractère à afficher
        setTimeout(this.typeText.bind(this), 50); // on rappelle la fonction dans 100ms et la vitesse de supression
      } else {
        // si on a supprimé tout le texte
        setTimeout(
          // on attend 500ms avant de recommencer à afficher le texte
          function () {
            // on passe isDeleting à false et on rappelle la fonction
            this.isDeleting = false; // pour afficher le texte
            this.typeText(); // on rappelle la fonction
          }.bind(this), // on bind le this pour pouvoir utiliser this dans la fonction
          500 // on attend 500ms avant de recommencer à afficher le texte
        );
      }
    }
  }
}
