import kursor from 'kursor';

export default class Kursor {
  constructor() {
    this.options = {
      type: 4,
      color: '#fff',
      //removeDefaultCursor: true,
    };
    this.init();
  }
  init() {
    new kursor(this.options);
  }
}
