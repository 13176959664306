export default class Modale {
  constructor(element) {
    this.element = element;
    this.boutonsModale = this.element.querySelectorAll('.toggle-modale');
    this.boutonsModale.forEach((bouton) => {
      bouton.addEventListener('click', this.ouvrirModale.bind(this));
    });
    this.modale = this.element.querySelector('.modale');
    this.fermeture = this.element.querySelector('.modale img');
    this.fermeture.addEventListener('click', this.closeModale.bind(this));
    this.fermetureX = this.element.querySelector('.modale__fermeture');
    this.fermetureX.addEventListener('click', this.closeModale.bind(this));
    this.init();
  }
  init() {}
  ouvrirModale(event) {
    const imageSelection = event.target;
    const imageModale = this.modale.querySelector('img');
    imageModale.src = imageSelection.src;
    this.modale.classList.add('visible');
  }
  closeModale(event) {
    this.modale.classList.remove('visible');
  }
}
