import Carousel from './components/Carousel';
import Header from './components/Header';
import Modale from './components/Modale';
import Kursor from './components/Kursor';
import Terminal from './components/Terminal';
import Scrolly from './components/Scrolly';
import Tilt from './components/Tilt';
import YouTube from './components/YouTube';
import Form from './components/Form';

export default class ComponentFactory {
  constructor() {
    this.componentInstances = [];
    this.componentList = {
      Carousel,
      Header,
      Modale,
      Kursor,
      Terminal,
      Scrolly,
      Tilt,
      YouTube,
      Form,
    };
    this.init();
  }

  init() {
    const components = document.querySelectorAll('[data-component]');

    for (let i = 0; i < components.length; i++) {
      const element = components[i];
      const componentNames = element.dataset.component.split(',');

      componentNames.forEach((componentName) => {
        componentName = componentName.trim();
        if (this.componentList[componentName]) {
          const instance = new this.componentList[componentName](element);
          this.componentInstances.push(instance);
        } else {
          console.log(`La composante ${componentName} n'existe pas`);
        }
      });
    }
  }
}
